<template>
    <main_pop_up
      v-if="showPopup"
      :visible="showPopup"
      :title="popupTitle"
      @close="closePopup"
    >
      <template #actions>
        <div class="popupContent">
          <img :src="popupImage" alt="..." class="popupImage" />
          <!-- Render HTML content from Firestore -->
          <div v-html="popupText" class="popupText"></div>
        </div>
      </template>
    </main_pop_up>
  </template>
  
  <script>
  import main_pop_up from './main_pop_up.vue'; 
  import { getFirestore, doc, getDoc, setDoc, serverTimestamp } from "firebase/firestore"; 
  import { Global_Settings_Collection } from '../global_config';
  import { Current_Month_Short, Current_Year } from '../global';
  
  export default {
    components: {
      main_pop_up,
    },
    data() {
      return {
        showPopup: false,
        popupTitle: '',
        popupText: '',
        popupImage: '',
        Global_Settings_Collection_Apply: Global_Settings_Collection,
        Current_Month_Short_Apply: Current_Month_Short,
        Current_Year_Apply: Current_Year,
      };
    },
    methods: {
      async checkStatus() {
        const db = getFirestore();
        const docRef = doc(db, this.Global_Settings_Collection_Apply, `Global_Settings`);
        const docSnap = await getDoc(docRef);
  
        // Check session storage to prevent showing the popup more than once
        const popupShown = sessionStorage.getItem('popupShown');
  
        if (popupShown) {
          this.showPopup = false;
          return;
        }
  
        if (docSnap.exists()) {
          const data = docSnap.data();
          this.popupTitle = data.Global_Settings_Pop_Up_Title || 'Welcome';
          this.popupText = data.Global_Settings_Pop_Up_Message || '<p>Welcome to SendSm1!</p>';
          this.popupImage = data.Global_Settings_Pop_Up_Image || 'https://firebasestorage.googleapis.com/v0/b/sendsm1-app.appspot.com/o/Assets%2FSendSm1_main_icon_2?alt=media&token=07be7fd1-1423-4868-9d5c-d5fb92c25145';
  
          // Show the popup only if the boolean field allows it
          if (data.Global_Settings_Show_Pop_Up === true) {
            this.showPopup = true;
          }
        } else {
          // Create the document if it doesn't exist
          await setDoc(docRef, {
            Global_Settings_ID: 'Global_Settings',
            Global_Settings_Pop_Up_Title: 'Notice',
            Global_Settings_Pop_Up_Message: '<p><b>SendSm1 is currently not open for customers. We will resume operations any day soon.</b> In the meantime, we are onboarding additional shoppers to ensure a seamless experience for you.</p>',
            Global_Settings_Pop_Up_Image: 'https://firebasestorage.googleapis.com/v0/b/sendsm1-app.appspot.com/o/Assets%2Fre_opening.webp?alt=media&token=f046ae18-45c8-46b2-bbfd-4e872cea4e90',
            Global_Settings_Show_Pop_Up: true,
            Global_Settings_Created_At: serverTimestamp(), // Use Firebase server timestamp
          });
          this.showPopup = true;
        }
      },
      closePopup() {
        this.showPopup = false;
        sessionStorage.setItem('popupShown', true); // Set session storage to prevent showing it again in the same session
      },
    },
    mounted() {
      this.checkStatus();
    },
  };
  </script>
  
  <style scoped>
  .popupImage {
    width: 100%;
    height: auto;
    margin-bottom: 16px;
  }
  
  .popupText {
    font-size: var(--font-size-medium);
    font-weight: 400;
    text-align: center;
    color: var(--base-300);
    line-height: 1.5;
  }
  
  .popupText strong {
    font-weight: 600;
  }
  </style>
  