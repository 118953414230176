import { createRouter, createWebHistory } from 'vue-router';
import store from './store';

const routes = [
  { 
    path: '/', 
    component: () => import('./components/page_make_request_1.vue'),
    meta: { requiresAuth: true }
  },
  { 
    path: '/request', 
    component: () => import('./components/page_make_request_2.vue'),
    meta: { requiresAuth: true }
  },
  { 
    path: '/track', 
    component: () => import('./components/request_display_2.vue'),
    meta: { requiresAuth: true }
  },
  { 
    path: '/track/:requestId', 
    component: () => import('./components/request_display_1.vue'),
    meta: { requiresAuth: true }
  },
  { 
    path: '/chat', 
    component: () => import('./components/page_chat_2.vue'),
    meta: { requiresAuth: true }
  },
  { 
    path: '/chat/:chatId', 
    component: () => import('./components/page_chat_1.vue'),
    meta: { requiresAuth: true }
  },
  { 
    path: '/signin', 
    component: () => import('./components/main_sign_in.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/signup', 
    component: () => import('./components/page_signup_2.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/forgotpassword', 
    component: () => import('./components/main_forgot_password.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/deliver', 
    component: () => import('./components/page_deliver_1.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/account', 
    component: () => import('./components/page_account.vue'),
    meta: { requiresAuth: false }
  },
  { 
    path: '/account/requests', 
    component: () => import('./components/page_account_display_requests_1.vue'),
    meta: { requiresAuth: true }
  },
  { 
    path: '/account/earnings', 
    component: () => import('./components/page_account_display_earnings_1.vue'),
    meta: { requiresAuth: true }
  },
  { 
    path: '/account/payables', 
    component: () => import('./components/page_account_pay_payables_1.vue'),
    meta: { requiresAuth: true }
  },
  { 
    path: '/account/edit', 
    component: () => import('./components/page_account_edit_account_1.vue'),
    meta: { requiresAuth: true }
  },
  { 
    path: '/account/delete', 
    component: () => import('./components/page_account_delete_account_1.vue'),
    meta: { requiresAuth: true }
  },
  { 
    path: '/howitworks', 
    component: () => import('./components/page_learn_more.vue'),
    meta: { requiresAuth: true }
  },
  // Wildcard route to redirect to '/' if path does not exist
  { path: '/:pathMatch(.*)*', redirect: '/' }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  // Check if route requires authentication
  if (to.meta.requiresAuth) {
    // Check if user data exists in the store
    if (!store.getters.userData) {
      // If user data does not exist, fetch it from the server
      store.dispatch('fetchUserData').then(() => {
        // Proceed to the next route after fetching user data
        next();
      });
    } else {
      // User data exists, proceed to the next route
      next();
    }
  } else {
    // Route does not require authentication, proceed to the next route
    next();
  }
});

export default router;
