////////////////////////////////TIME

var Current_Year = new Date().getFullYear();
const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
let Current_Month = month[new Date().getMonth()];
const monthsShort = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
let Current_Month_Short = monthsShort[new Date().getMonth()];
const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
let Current_Week_Day = weekday[new Date().getDay()];
var Current_Day = new Date().getDate();

function Time_Ago_Chat_Fn(dateString) {
    // Convert the provided date string to a Date object
    const date = new Date(dateString);
    
    // Calculate the time difference between the provided date and the current date
    const differenceTravel = Date.now() - date.getTime();
  
    var seconds = Math.floor(differenceTravel / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    var weeks = Math.floor(days / 7);
    var months = Math.floor(days / 30); // Approximation: Assuming 30 days in a month
    var years = Math.floor(days / 365); // Approximation: Assuming 365 days in a year
    var storeTime = 0;
  
    if (seconds < 60) {
      storeTime = seconds > 1 ? seconds + " secs ago" : seconds + " sec ago";
    } else if (minutes < 60) {
      storeTime = minutes > 1 ? minutes + " mins ago" : minutes + " min ago";
    } else if (hours < 24) {
      storeTime = hours > 1 ? hours + " hrs ago" : hours + " hr ago";
    } else if (days < 7) {
      storeTime = days > 1 ? days + " days ago" : days + " day ago";
    } else if (weeks < 4) {
      storeTime = weeks > 1 ? weeks + " wks ago" : weeks + " wk ago";
    } else if (months < 12) {
      storeTime = months > 1 ? months + " mos ago" : months + " mo ago";
    } else {
      storeTime = years > 1 ? years + " yrs ago" : years + " yr ago";
    }
  
    return storeTime;
}



function Time_Ago_Full(dateString) {
  // Convert the provided date string to a Date object
  const date = new Date(dateString);
  
  // Calculate the time difference between the provided date and the current date
  const differenceTravel = Date.now() - date.getTime();
  
  var seconds = Math.floor(differenceTravel / 1000);
  var minutes = Math.floor(seconds / 60);
  var hours = Math.floor(minutes / 60);
  var days = Math.floor(hours / 24);
  var weeks = Math.floor(days / 7);
  
  if (weeks > 0) {
    if (days % 7 > 0) {
      return `${weeks} week${weeks > 1 ? 's' : ''}, ${days % 7} day${(days % 7) > 1 ? 's' : ''}`;
    } else {
      return `${weeks} week${weeks > 1 ? 's' : ''}`;
    }
  } else if (days > 0) {
    return `${days} day${days > 1 ? 's' : ''}`;
  } else if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''}`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes > 1 ? 's' : ''}`;
  } else {
    return `${seconds} second${seconds > 1 ? 's' : ''}`;
  }
}



function format_date_full(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
}







function getCurrentDate_In_3_space_Format() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  let month = currentDate.getMonth() + 1;
  let day = currentDate.getDate();

  // Add leading zero if month/day is less than 10
  month = month < 10 ? '0' + month : month;
  day = day < 10 ? '0' + day : day;

  return `${year}-${month}-${day}`;
}



function isToday_Long_Date(dateString) {
  // Convert the given date string to a Date object
  const givenDate = new Date(dateString);
  
  // Get the current date
  const currentDate = new Date();
  
  // Check if the year, month, and day of the given date match the current date
  return givenDate.getFullYear() === currentDate.getFullYear() &&
         givenDate.getMonth() === currentDate.getMonth() &&
         givenDate.getDate() === currentDate.getDate();
}




function getFormattedTime() {
  const addZero = (i) => (i < 10 ? "0" + i : i);
  const HoursMinSeconds_d = new Date();
  let h = addZero(HoursMinSeconds_d.getHours());
  let m = addZero(HoursMinSeconds_d.getMinutes());
  return `${h}:${m}`;
}






function getCurrentMonth_2_Digits() {
  const currentMonth = new Date().getMonth() + 1; // getMonth() returns 0-11, so add 1
  // Add leading zero if month is a single digit
  const formattedMonth = currentMonth < 10 ? '0' + currentMonth : currentMonth;
  return formattedMonth.toString(); // Convert to string
}


function getCurrentDate_2_Digits() {
  const currentDate = new Date();
  const day = currentDate.getDate();
  // Add leading zero if day is a single digit
  const formattedDay = day < 10 ? '0' + day : day;
  return formattedDay;
}


function getCurrentHour_2_Digits() {
const currentHour = new Date().getHours();
// Add leading zero if hour is a single digit
const formattedHour = currentHour < 10 ? '0' + currentHour : currentHour;
return formattedHour.toString(); // Convert to string
}

function getCurrentMinutes_2_Digits() {
const currentMinutes = new Date().getMinutes();
// Add leading zero if minutes is a single digit
const formattedMinutes = currentMinutes < 10 ? '0' + currentMinutes : currentMinutes;
return formattedMinutes.toString(); // Convert to string
}

function getCurrentSeconds_2_Digits() {
const currentSeconds = new Date().getSeconds();
// Add leading zero if seconds is a single digit
const formattedSeconds = currentSeconds < 10 ? '0' + currentSeconds : currentSeconds;
return formattedSeconds.toString(); // Convert to string
}




function generateLinkCode() {
  const codeLength = 8;
  // Possible characters for the code
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let linkCode = '';
  // Generate a random code
  for (let i = 0; i < codeLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    linkCode += characters.charAt(randomIndex);
  }
  
  linkCode = `${Current_Year}${getCurrentMonth_2_Digits()}${getCurrentDate_2_Digits()}${getCurrentHour_2_Digits()}${getCurrentMinutes_2_Digits()}${getCurrentSeconds_2_Digits()}${linkCode}`;
  return linkCode;
}







///TIME PASSED

function getDaysSince(date) {
  var diffInMs = new Date() - new Date(date);
  return Math.floor(diffInMs / (1000 * 60 * 60 * 24)); // Corrected to use Math.floor for accurate days count
}

function getMonthsSince(date) {
  var diffInMs = new Date() - new Date(date);
  return Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 30)); // Corrected to use Math.floor for accurate months count
}

function getYearsSince(date) {
  var diffInMs = new Date() - new Date(date);
  return Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 365)); // Corrected to use Math.floor for accurate years count
}


function getPreviousMonth_Short() {
  const date = new Date();
  date.setMonth(date.getMonth() - 1);

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  const previousMonth = `${monthNames[date.getMonth()]}_${date.getFullYear()}`;
  return previousMonth;
}









////////////////NEXT PAYMENT DATE 

function getNextPaymentDate30Days() {
  const currentDate = new Date();
  const nextPaymentDate = new Date(currentDate);
  nextPaymentDate.setDate(nextPaymentDate.getDate() + 30);
  return format_date_full(nextPaymentDate);
}


function getNextPaymentDate1Year() {
  const currentDate = new Date();
  const nextPaymentDate = new Date(currentDate);
  nextPaymentDate.setFullYear(nextPaymentDate.getFullYear() + 1);
  return format_date_full(nextPaymentDate);
}


/////////////////////////////////////////

function getDayLabel(date) {
  const today = new Date();
  const tomorrow = new Date(today);
  const yesterday = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  yesterday.setDate(today.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    return 'Today';
  } else if (date.toDateString() === tomorrow.toDateString()) {
    return 'Tomorrow';
  } else if (date.toDateString() === yesterday.toDateString()) {
    return 'Yesterday';
  } else {
    return this.formatFullDate(date);
  }
}







/////////////////////FIREBASE

function isFirebaseStorageURL(imageUrl) {
    // Check if imageUrl is null or undefined
    if (!imageUrl) {
      return false; // Return false if imageUrl is null or undefined
    }
  
    const firebaseStorageKeywords = [
      "firebasestorage.googleapis.com",
      "o/",
      "alt=media",
      "token=",
    ];
  
    // Check if all the Firebase Storage keywords are present in the URL
    return firebaseStorageKeywords.every(keyword => imageUrl.includes(keyword));
}

//////////////////////////////////






////////MONEY & NUMBERS


function formatNumberWithKMB(number) {
  number = parseInt(number, 10); // Ensure the number is an integer
  if (number >= 1000000000) {
    const roundedNumber = Math.round(number / 100000000) / 10;
    return `${roundedNumber}B`;
  } else if (number >= 1000000) {
    const roundedNumber = Math.round(number / 100000) / 10;
    return `${roundedNumber}M`;
  } else if (number >= 1000) {
    const roundedNumber = Math.round(number / 100) / 10;
    return `${roundedNumber}k`;
  } else {
    return number?.toString() ?? '0';
  }
}


function Format_Money_Fn(price) {
  var formattedPrice = parseFloat(price).toFixed(2);
  return formattedPrice;
}


function Format_Money_With_Spaces_Fn(price) {
  var formattedPrice = parseFloat(price).toFixed(2);
  formattedPrice = formattedPrice.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return formattedPrice;
}

function Format_To_1_Digit_After_Comma_Fn(Value) {
  var formattedValue = parseFloat(Value).toFixed(1);
  return formattedValue;
}
///////////////////////////












//////////////////////LOCATIONS

function Make_Location_Shorter_Fn(Location){
  var Shorter_Location = Location;
  if( Location != null && Location != undefined){
      Shorter_Location = Location.split(",")[0];
  }
  return Shorter_Location;
}



function Remove_SA_On_Location_Fn(Location) {
  var Original_Location = Location;
  var Trimmed_Location_For_User_Location = 0;
  if (Original_Location != null && Original_Location != "null" && Original_Location != undefined) {
      if (Original_Location.includes("South Africa")) {
          Trimmed_Location_For_User_Location = Original_Location.substring(0, Original_Location.lastIndexOf(","));
      } else {
          Trimmed_Location_For_User_Location = Location;
      }
  } else {
      Trimmed_Location_For_User_Location = Original_Location;
  }
  return Trimmed_Location_For_User_Location;
}






function roundCoordinate_New(number) {
  // Convert number to a string
  let numStr = number.toFixed(1).replace('.', ','); // One decimal place and replace dot with comma

  // Ensure two whole numbers
  const parts = numStr.split(',');
  if (parts[0].length === 1) {
    // Add a leading zero if there's only one whole number
    parts[0] = '0' + parts[0];
  } else if (parts[0].length > 2) {
    // Trim to the last two digits if there are more than two whole numbers
    parts[0] = parts[0].slice(-2);
  }

  return parts.join(',');
}




function roundCoordinate(num) {
  const isNegative = num < 0;
  num = Math.abs(num); // Remove negative sign
  num = parseFloat(num);

  const roundedNum = Math.floor(num * 10) / 10; // Round down to one decimal place
  const decimalPart = num - Math.floor(num); // Extract decimal part
  const secondDigit = Math.abs(Math.floor(decimalPart * 100) % 10); // Get the absolute value of the second digit after the decimal point

  // If the absolute value of the second digit is greater than or equal to 5, round up, otherwise, keep the roundedNum as is
  const result = secondDigit > 5 ? roundedNum + 0.1 : roundedNum;

  return isNegative ? -result : result; // Restore negative sign if necessary
}



////////////////////////////////////////









/////////////////////////////////REQUESTS

function getRequestStatus(Request_Taken, Request_Delivered, Request_Cancelled) {
  if (Request_Cancelled === true) {
    return "Cancelled";
  } else if (Request_Taken === false) {
    return "Waiting";
  } else if (Request_Taken === true && Request_Delivered === false) {
    return "In progress";
  } else if (Request_Taken === true && Request_Delivered === true && Request_Cancelled === false) {
    return "Delivered";
  } else {
    return "Unknown"; // Handle any other cases if needed
  }
}




function checkDeliveryDate(dateString) {
  const deliveryDate = new Date(dateString);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (deliveryDate < today) {
    return 'Delivery date passed already';
  } else {
    const differenceInDays = Math.floor((deliveryDate - today) / (1000 * 60 * 60 * 24));

    if (differenceInDays === 0) {
      return 'Deliver today';
    } else if (differenceInDays === 1) {
      return 'Deliver tomorrow';
    } else if (differenceInDays > 0) {
      return `Deliver in ${differenceInDays} day${differenceInDays > 1 ? 's' : ''}`;
    }
  }
}



function checkDeliveryDate_2(dateString, request_Type) {
  const deliveryDate = new Date(dateString);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (deliveryDate < today) {
    return 'Delivery date passed already';
  } else {
    const differenceInDays = Math.floor((deliveryDate - today) / (1000 * 60 * 60 * 24));

    let action = 'Deliver';
    switch (request_Type) {
      case 'Send Someone to Shop':
        action = 'Shop';
        break;
      case 'Send Someone to Collect':
        action = 'Collect';
        break;
      case 'Send Someone to Print':
        action = 'Print';
        break;
      case 'Send Someone to Queue':
        action = 'Queue';
        break;
      default:
        action = 'Deliver';
        break;
    }

    if (differenceInDays === 0) {
      return `${action} today`;
    } else if (differenceInDays === 1) {
      return `${action} tomorrow`;
    } else if (differenceInDays > 0) {
      return `${action} in ${differenceInDays} day${differenceInDays > 1 ? 's' : ''}`;
    }
  }
}

//////////////////////////////////////////////////









////////////////////////////////////LINKS

function getDomainWithHttps() {
  const protocol = window.location.protocol; // Example: "http:" or "https:"
  const hostname = window.location.hostname; // Example: "www.example.com" or "localhost"
  const port = window.location.port; // Example: "8080"

  const domainWithHttps = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  return domainWithHttps;
}


//////////////////////////////////////////////////





///////////////////////////PLACES

function Check_For_Location_Names(Request_Area, Target_Location) {
  // Create a regular expression pattern to match the target location with optional hyphen and additional characters
  const pattern = new RegExp(`\\b${Target_Location}(?:-\\w+)?\\b`, 'i');

  // Check for an exact match or if the target location is included
  const includesLocation = Request_Area.toLowerCase().includes(Target_Location.toLowerCase());

  // Return true if either condition is met
  return pattern.test(Request_Area) || includesLocation;
}


///////////////////////////////////////








// Export both functions and variables in a single object
module.exports = { 
    Current_Year,
    Current_Month,
    Current_Month_Short,
    Current_Week_Day,
    Current_Day,
    Time_Ago_Chat_Fn, 
    isFirebaseStorageURL,
    formatNumberWithKMB,
    Format_Money_Fn,
    Format_Money_With_Spaces_Fn,
    Format_To_1_Digit_After_Comma_Fn,
    getCurrentDate_In_3_space_Format,
    Make_Location_Shorter_Fn,
    Remove_SA_On_Location_Fn,
    Time_Ago_Full,
    format_date_full,
    getDaysSince,
    getMonthsSince,
    getYearsSince,
    getNextPaymentDate30Days,
    getNextPaymentDate1Year,
    getRequestStatus,
    getDomainWithHttps,
    isToday_Long_Date,
    getCurrentMonth_2_Digits,
    getCurrentDate_2_Digits,
    getCurrentHour_2_Digits,
    getCurrentMinutes_2_Digits,
    getCurrentSeconds_2_Digits,
    generateLinkCode,
    getFormattedTime,
    getPreviousMonth_Short,
    getDayLabel,
    roundCoordinate,
    roundCoordinate_New,
    Check_For_Location_Names,
    checkDeliveryDate,
    checkDeliveryDate_2

};
