
////////////////////////////////PRICES & %s
var Global_Business_Commission_Percentage = 20; //R20%
var Global_Price_Difference = 10; //R10
var Global_Default_Base_Price = 15; //R15
var Global_Additonal_Amount_For_Night_Time_Prices = 5; //R5
var Global_Yoco_Transaction_Fee = 3; //%

var Global_Store_Minimum_To_Pay_Business = 30;
var Global_Store_Days_Left_To_Pay_Business = 7;
var Global_Store_Days_Left_To_Give_Mild_Warning_For_Payments = 4;
var Global_Store_Days_Left_To_Give_Final_Warning_For_Payments = 5;

var Hatfield_Base_Price = 15;
var Hillcrest_Base_Price = 15;
var Arcadia_Base_Price = 15;
var Brooklyn_Base_Price = 15;
var Pretoria_Central_Base_Price = 15;
var Vaal_Base_Price = 50;
var Malamulele_Base_Price = 30;

var Global_Promo_Points_To_Earn_Through_Sign_Up = 100;

///////////////////////////////////



////////////////////////////////DB Values
var Users_Collection = "SendSm1_Users";
var Payments_Collection = "SendSm1_Payments";

var Users_Storage_Path = "SendSm1/Users";
var Screenshots_Storage_Path = "SendSm1/Screenshots";
var Chats_Storage_Path = "SendSm1/Chats";

var Emails_Collection = "emails";

var Requests_Collection = "SendSm1_Requests";
var Monthly_Stats_Collection = "SendSm1_Monthly_Stats";
var Shops_Collection = "SendSm1_Shops";
var Bids_Collection = "SendSm1_Bids";
var Notifications_Collection = "SendSm1_Notifications";
var Ratings_Collection = "SendSm1_Ratings";

var Chat_Message_Collection = "SendSm1_Chat_Message";
var Chat_Collection = "SendSm1_Chat_Chats";

var Global_Settings_Collection = "SendSm1_Global_Settings";
/////////////////////////////////


//////////Keys
var Google_Maps_API_Key = "AIzaSyCseKZZdidwHZ6Pm8MdmB_LcvWm69TAByc";
var Vapid_Key = 'BOiAI0H1LKWX4ABcroB7JEEGfNcvFKH60vTn1iFgDadidMUexlLJZFYjJMTn0fO4WjEHIlmM8DFNgKyfpg6xSIg';
///////////////


////////////CONTACTS & LINKS
var Support_Email = "SendSm1.com@gmail.com";
var Admin_Email = "SendSm1.com@gmail.com";
var Admin_Phone_Number = "27762240799";
var Admin_Emails_Array = ['tyrese.makhubele@gmail.com', 'silindatutoring@gmail.com'];
var Admin_Dashboard_Url = "https://sendsm1-admin.web.app/";
////////////////////


////////////ACCOUNT TYPES
var Admin_Account_Role = "SendSm1 Admin";
var Primary_Account_Role = "SendSm1 Shopper";
var Secondary_Account_Role = "SendSm1 Customer";
//////////////////////


// Export variables in a single object
module.exports = { 
    Users_Collection,
    Users_Storage_Path,
    Screenshots_Storage_Path,
    Chats_Storage_Path,
    Payments_Collection,
    Google_Maps_API_Key,
    Vapid_Key,
    Support_Email,
    Requests_Collection,
    Monthly_Stats_Collection,
    Emails_Collection,
    Shops_Collection,
    Bids_Collection,
    Notifications_Collection,
    Chat_Collection,
    Chat_Message_Collection,
    Ratings_Collection,
    Admin_Email,
    Admin_Phone_Number,
    Admin_Emails_Array,
    Admin_Dashboard_Url,
    Global_Business_Commission_Percentage,
    Global_Price_Difference,
    Global_Default_Base_Price,
    Global_Additonal_Amount_For_Night_Time_Prices,
    Global_Store_Minimum_To_Pay_Business,
    Global_Store_Days_Left_To_Pay_Business,
    Global_Store_Days_Left_To_Give_Mild_Warning_For_Payments,
    Global_Store_Days_Left_To_Give_Final_Warning_For_Payments,
    Global_Yoco_Transaction_Fee,
    Hatfield_Base_Price,
    Hillcrest_Base_Price,
    Arcadia_Base_Price,
    Pretoria_Central_Base_Price,
    Brooklyn_Base_Price,
    Vaal_Base_Price,
    Malamulele_Base_Price,
    Global_Promo_Points_To_Earn_Through_Sign_Up,
    Admin_Account_Role,
    Primary_Account_Role,
    Secondary_Account_Role,
    Global_Settings_Collection
};
