// store.js
import { createStore } from 'vuex';
import { getDoc, doc } from 'firebase/firestore/lite';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore/lite';
import {Users_Collection} from './global_config'

export default createStore({
  state: {
    userData: null,
    Selected_Option: null,
    Selected_Object: null
  },
  mutations: {
    setUserData(state, userData) {
      state.userData = userData;
    },

    Update_Selected_Option(state, Selected_Option) {
      state.Selected_Option = Selected_Option;
    },

    Update_Selected_Object(state, Selected_Object) {
      state.Selected_Object = Selected_Object;
    }

  },
  actions: {

    async fetchUserData({ commit }) {
      const auth = getAuth();
      const db = getFirestore();
      const user = auth.currentUser;
      try {
        if (user) {
          const userDoc = await getDoc(doc(db, Users_Collection, user.uid));
          if (userDoc.exists()) {
            commit('setUserData', userDoc.data());
            console.log('Fetched data');
          } else {
            console.error('User document does not exist with '+user.uid);
          }
        } else {
          //console.error('User not logged in');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    },

    Update_Selected_Option(context, newData) {
      context.commit('Update_Selected_Option', newData);
    },

    Update_Selected_Object(context, newData) {
      context.commit('Update_Selected_Object', newData);
    }
    
  },
  getters: {
    userData: (state) => state.userData
  }
});
