// firebasePlugin.js
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore/lite';

export const firebasePlugin = {
  install: (app) => {
    const firebaseConfig = {
      apiKey: "AIzaSyBg-CPhSMlVw7bHbJ30eoddeCM3fSA2qPs",
      authDomain: "sendsm1-app.firebaseapp.com",
      projectId: "sendsm1-app",
      storageBucket: "sendsm1-app.appspot.com",
      messagingSenderId: "686988952923",
      appId: "1:686988952923:web:e19a71a19fa670df9cc67e",
      measurementId: "G-FX47M373E3"
    };

    const firebaseApp = initializeApp(firebaseConfig);
    const auth = getAuth(firebaseApp);
    const db = getFirestore(firebaseApp);

    // Add an auth state change listener
    onAuthStateChanged(auth, (user) => {
      //app.config.globalProperties.$currentUser_Auth = user;
      
      // Set global auth value to null if user is signed out
      if (!user) {
        app.config.globalProperties.$currentUser_Auth = null;
        console.log("Auth Changed Signed out", user);
      }
      else{
        app.config.globalProperties.$currentUser_Auth = user;
        //console.log("Auth Changedd Signed In", user);
        console.log("Auth Changed Signed In");
      }


    });
    

    app.config.globalProperties.$auth = auth;
    app.config.globalProperties.$db = db;
  }
};
